// import MicroModal from 'micromodal';
import EmblaCarousel from 'embla-carousel';
import { setupDotBtns, generateDotBtns, selectDotBtn } from './libs/dotButton';
import { autoplay } from './libs/autoplay';

function home_header() {
    const home_header = document.querySelector('.wrapper');
    if (home_header) {
        display_header();
        window.addEventListener('scroll', display_header);
    }
    function display_header(e) {
        let ys = window.scrollY || window.pageYOffset;
        if (ys > 100) {
            home_header.classList.add('on');
        } else {
            home_header.classList.remove('on');
        }
    }
}

function gnav() {
    const trigger = document.querySelector('.menu-trigger');
    const header_nav = document.querySelector('.drawer');
    trigger.addEventListener('click', (e) => {
        e.preventDefault();
        trigger.classList.toggle('active');
        header_nav.classList.toggle('active');
    }, false);
}

function home_slide() {
    const emblaNode = document.querySelector('.menu-box-slide');
    if (emblaNode) {
        const options = {
            loop: true,
            align: 'start',
        };

        const dots = document.querySelector('.menu-box-slide-dots');
        const embla = EmblaCarousel(emblaNode, options);
        const autoplayer = autoplay(embla, 4000);
        const dotsArray = generateDotBtns(dots, embla);
        const setSelectedDotBtn = selectDotBtn(dotsArray, embla);
        setupDotBtns(dotsArray, embla);
        embla.on('select', setSelectedDotBtn);
        embla.on('init', setSelectedDotBtn);
        embla.on('pointerDown', autoplayer.stop);
        embla.on('init', autoplayer.play);
    }
}

function home_modal() {
    const emblaNode = document.querySelector('.menu-box-slide');
    if (emblaNode) {
        MicroModal.init({
            openTrigger: 'data-modal-open',
            closeTrigger: 'data-modal-close',
            disableScroll: true,
        });
    }
}

function scrollObserver() {
    // オプション指定
    const options = {
        // 要素の見えている割合が20%を超える度にコールバックを実行
        threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
    };

    // 交差した時に発生するコールバック
    const callback = (entries, observer) => {
        entries.forEach((entry) => {
            // 交差している領域の割合が20%を超えた場合
            if (entry.intersectionRatio > 0.4) {
                // アニメーションや画像の読み込みなどの処理
                entry.target.classList.add('on');
            }
        });
    };

    // IntersectionObserverをインスタンス化
    const observer = new IntersectionObserver(callback, options);

    // 監視する要素の配列を取得
    let observers = [];
    Array.from(document.querySelectorAll('.js-scroll')).forEach(function (obj) {
        observers.push(obj);
    });

    // 配列に指定した要素をIntersectionObserverで監視
    observers.forEach((el) => {
        observer.observe(el);
    });
}

function init() {
    home_header();
    gnav();
    scrollObserver();
    home_slide();
    home_modal();
}

document.addEventListener('DOMContentLoaded', () => {
    init();
});

window.addEventListener('load', () => {
});
